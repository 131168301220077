<template>
  <!-- 临登任务 -->
  <div class="facilityBox proTask">
    <!-- 表单 -->
    <el-form
      class="proTaskForm"
      ref="proTaskForm"
      :model="proTaskForm"
      :inline="true"
    >
      <el-form-item prop="carTypeValue">
        <el-input
          placeholder="请输入证件号码"
          v-model="proTaskForm.carTypeValue"
          clearable
        >
          <el-select
            v-model="proTaskForm.carType"
            slot="prepend"
            style="width: 150px;"
          >
            <el-option label="司机身份证" value="司机身份证"></el-option>
            <el-option label="办税人身份证" value="办税人身份证"></el-option>
            <el-option
              label="变更办税人身份证"
              value="变更办税人身份证"
            ></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="企业名称：" prop="invoiceApplicantName">
        <el-input
          v-model.trim="proTaskForm.invoiceApplicantName"
          placeholder="请输入企业名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="临登状态：" prop="loginStatus">
        <el-select
          v-model="proTaskForm.loginStatus"
          placeholder="请选择临登状态"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="待临登" value="待临登"></el-option>
          <el-option label="已临登" value="已临登"></el-option>
          <el-option label="临登失败" value="临登失败"></el-option>
          <el-option label="已注销" value="已注销"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="税务机关：" prop="taxManName">
        <el-input
          v-model="proTaskForm.taxManName"
          placeholder="请输入税务机关"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="办税人姓名：" prop="taxAgentName">
        <el-input
          v-model="proTaskForm.taxAgentName"
          placeholder="请输入办税人姓名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="增值税认定：" prop="addedTax">
        <el-select
          v-model="proTaskForm.addedTax"
          placeholder="请选择增值税认定类型"
          clearable
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="季度" value="季度"></el-option>
          <el-option label="月度" value="月度"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="个税认定：" prop="geAddedTax">
        <el-select
          v-model="proTaskForm.geAddedTax"
          placeholder="请选择个税认定类型"
          clearable
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="季度" value="季度"></el-option>
          <el-option label="月度" value="月度"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="临登日期：" prop="proTaskTime">
        <el-date-picker
          v-model="proTaskForm.proTaskTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label-width="10px">
        <el-button
          type="primary"
          size="medium"
          @click="search()"
          icon="el-icon-search"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="resetForm()"
          icon="el-icon-delete"
          >清空</el-button
        >
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="downLoadRow()"
          >导出表格
        </el-button>
        <el-button
          icon="el-icon-upload2"
          type="primary"
          size="medium"
          @click="upLoadXlsx"
          >导入表格(单次最大可上传{{ maxImportNum }}条)
          <input
            ref="proTaskRow"
            type="file"
            style="display:none"
            @change="importproTaskRow"
          />
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="pagination.list"
      :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      v-loading="loading"
      :row-key="getRowKeys"
      ref="tableRef"
    >
      <el-table-column
        align="center"
        prop="RowIndex"
        label="序号"
        width="50"
        fixed="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="DriverName"
        label="司机姓名"
        width="100"
        fixed="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="DriverIDCard"
        label="司机身份证号码"
        width="200"
        fixed="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="DriverPhone"
        label="司机手机号码"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="InvoiceApplicantUserName"
        label="所属企业"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="RegStatus"
        label="临登状态"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="RegTime"
        label="临登日期"
        width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.RegTime ? scope.row.RegTime.split(" ")[0] : "" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="TaxAuthority"
        label="税务机关"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="TaxOperator"
        label="办税人"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="TaxOperatorIDCard"
        label="办税人身份证号码"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="TaxOperatorPhone"
        label="办税人手机号码"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="NewTaxOperator"
        label="变更后办税人"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="NewTaxOperatorIDCard"
        label="变更后办税人身份证号码"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="NewTaxOperatorPhone"
        label="变更后办税人手机号码"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="AddedTaxCognize"
        label="增值税附加认定"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="PersonalTaxCognize"
        label="个税认定"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="Remark"
        label="备注"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0 0 0">
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :current-page.sync="pagination.pageIndex"
        :page-size="pagination.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>

    <!-- 上传错误信息提示框 -->
    <el-dialog
      :visible.sync="flag.uploadErrorDialog"
      title="错误说明"
      center
      append-to-body
    >
      <div style="height:60vh;overflow-y:scroll">
        <el-table
          :data="uploadErrorList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        >
          <el-table-column
            align="center"
            prop="Index"
            label="错误行"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Msg"
            label="错误信息"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  DriverTaxDeclaration,
  DriverTaxDeclarationExportAsync,
  DriverTaxDeclarationImportAsync,
} from "@/api/proTask/proTask.js";
import { GetSysValueAsync } from "@/api/common/common.js";

export default {
  data() {
    return {
      flag: {
        uploadErrorDialog: false, // 上传错误信息提示框
      },
      uploadErrorList: [], // 上传错误信息列表
      proTaskForm: {
        carType: "司机身份证", // 身份证类型
        carTypeValue: "", // 身份证号码
        invoiceApplicantName: "", // 企业名称
        loginStatus: "", // 临登状态
        taxManName: "", // 税务机关
        taxAgentName: "", // 办税人名称
        addedTax: "", // 增值税认定
        geAddedTax: "", // 个税认定
        proTaskTime: [], // 临登时间
      },
      loading: false,
      tableData: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 10,
        list: [],
      },
      maxImportNum: 1000, // 最大导入条数
    };
  },
  created() {
    this.search();

    GetSysValueAsync({
      keyType: "TaxDeclaration",
      keyName: "TaxDeclaration",
    }).then((res) => {
      let { KeyValue = 1000 } = res.data;
      this.maxImportNum = KeyValue;
    });
  },
  methods: {
    // 搜索
    search() {
      this.pagination.pageIndex = 1;
      this.getProTaskList();
    },

    // 清空
    resetForm() {
      this.$refs.proTaskForm.resetFields();
      this.proTaskForm.carType = "司机身份证";
      this.proTaskForm.proTaskTime = [];
      this.search();
    },

    // 获取临登列表
    getProTaskList() {
      this.loading = true;
      let params = {
        ...this.proTaskForm,
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.pageIndex,
        startTime: this.proTaskForm.proTaskTime[0]
          ? this.proTaskForm.proTaskTime[0]
          : "", // 临登开始时间
        endTime: this.proTaskForm.proTaskTime[1]
          ? this.proTaskForm.proTaskTime[1]
          : "", // 临登结束时间
      };
      DriverTaxDeclaration(params)
        .then((res) => {
          this.pagination.list = res.data.DataList;
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 分页
    handleCurrentChange(e) {
      this.pagination.pageIndex = e;
      this.getProTaskList();
    },

    // 切换条数
    handlesizeChange(e) {
      this.pagination.pageSize = e;
      this.getProTaskList();
    },

    // el-table设置分页多选
    getRowKeys(row) {
      return row.Id;
    },

    // 导出表格
    downLoadRow() {
      let params = {
        ...this.proTaskForm,
        startTime: this.proTaskForm.proTaskTime[0]
          ? this.proTaskForm.proTaskTime[0]
          : "", // 临登开始时间
        endTime: this.proTaskForm.proTaskTime[1]
          ? this.proTaskForm.proTaskTime[1]
          : "", // 临登结束时间
      };
      DriverTaxDeclarationExportAsync(params).then((res) => {
        this.$notify({
          title: "下载提示",
          message: res.data,
          position: "top-right",
          type: "success",
        });
      });
    },

    // 导入表格
    upLoadXlsx() {
      this.$refs.proTaskRow.click();
      this.$refs.proTaskRow.value = "";
    },

    // 提交 导入表格
    importproTaskRow(e) {
      const loading = this.$loading({
        lock: true,
        text: "导入表格中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const file = e.srcElement.files[0] || e.target.files[0];
      let formData = new FormData();
      formData.append("formFile", file);
      DriverTaxDeclarationImportAsync(true, formData)
        .then((res) => {
          let { data } = res;
          if (!data.length) {
            this.$message.success("导入成功");
            this.search();
          } else {
            this.$message.error("导入失败");
            this.uploadErrorList = data;
            this.flag.uploadErrorDialog = true;
          }
        })
        .finally(() => {
          this.$refs.proTaskRow.value = "";
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
